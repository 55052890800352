<template>
    <div>
        <!-- <material-database></material-database> -->

        <div class="card">
            <h3 class="card_title">
                <indicator class="marginAuto" :condition="!verifiedSteps.material" required="1" :ttle="verifiedSteps.material"></indicator>
                Verwendetes Material
            </h3>
            <div class="padding">
                <div class="box">
                    <p v-if="!Object.keys(materials).length">Lade Materialien</p>
                    <div v-else class="flex">
                        <p style="margin: auto 8px auto 0;"><b>Suche:</b></p>
                        <searchlist :values="materials_codes" :displayvalues="materials_names" :placeholder="material_selected" :firstsel="0" @select="changeSelected($event)"></searchlist>
                        <img v-if="$store.getters.material_information(material_selected).custom" @click="$store.commit('material_delete', material_selected)" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" class="pointer" style="margin-left: 1em" alt="">
                    </div>
                    <!-- <p>Lade Material...</p> -->
                </div>
                <div class="box">
                    <p class="textspacing"><b>Allgemein:</b></p>
                    <div class="selectGrid indent textspacing">
                        <p>Name:</p>
                        <input type="text" :value="material.name" @input="changeSelectedProperty('name', $event.target.value)">
                        <p>Code:</p>
                        <input type="text" :value="material.codes" @input="changeSelectedProperty('codes', $event.target.value)">
                        <p>Notiz:</p>
                        <textarea type="text" :value="material.description" @input="changeSelectedProperty('description', $event.target.value)"></textarea>
                    </div>
                </div>

                <div class="box">
                    <p class="textspacing"><b>Form und Preis:</b></p>
                    <div class="selectGrid indent textspacing">
                        <p>Materialtyp:</p>
                        <div class="flex">
                            <p  class="radius pointer padding_small fitContent dark" :class="{blue: material.type ==t}" @click="changeSelectedProperty('type', t)" v-for="t in types_available" :key="t">{{dict(t)}}</p>
                            <!-- <p class="radius pointer padding_small fitContent dark" :class="{blue: material.type == 'rod'}" @click="changeSelectedProperty('type', 'rod')">{{dict('rod')}}</p> -->
                            <!-- <p class="radius pointer padding_small fitContent dark" :class="{blue: material.type == 'piece'}" @click="changeSelectedProperty('type', 'piece')">{{dict('piece')}}</p> -->
                        </div>                
                        <p class="textspacing">Preis:</p>
                        <div class="flex">
                            <input v-if="material.type" type="number" :value="material['price_'+material.type]" @input="changeSelectedProperty('price_'+material.type, $event.target.value)">
                            <p v-if="material.type == 'rod'">{{dict('€/rod')}}</p>
                            <p v-else-if="material.type == 'piece'">{{dict('€/piece')}}</p>
                            <!-- <select v-if="material.type == 'rod'" :value="material.price_type" @input="changeSelectedProperty('price_type', $event.target.value)" >
                                <option value="rod">{{dict('€/rod')}}</option>
                                <option value="m">{{dict('€/m')}}</option>
                                <option value="piece">{{dict('€/piece')}}</option>
                            </select> -->
                            <!-- <select v-else-if="material.type == 'piece'" :value="material.price_type" @input="changeSelectedProperty('price_type', $event.target.value)" >
                                <option value="kg">{{dict('€/kg')}}</option>
                                <option value="piece">{{dict('€/piece')}}</option>
                            </select> -->
                            <p v-else-if="material.type == 'm'">€/m</p>
                        </div>
                        <p v-if="material.type == 'rod'">Stangenlänge:</p>
                        <div v-if="material.type == 'rod'" class="flex">
                            <input type="number" step="0.5" :value="material.rod_length" @input="changeSelectedProperty('rod_length', $event.target.value)">
                            <p>m</p>
                        </div>
                        <p v-if="material.type == 'rod'">Reststück:</p>
                        <div v-if="material.type == 'rod'" class="flex">
                            <input type="number" :value="material.rod_remainder" @input="changeSelectedProperty('rod_remainder', $event.target.value)">
                            <p>cm</p>
                        </div>

                    </div>
                </div>
                
                <!-- <div class="box">
                    <p class="textspacing"><b>Zerspanung:</b></p>
                    <div class="selectGrid indent textspacing">
                        <p>Werkstoff:</p>
                        <div class="flex">
                            <p @click="changeSelectedProperty('group', 'p')" class="pointer padding_small dark radius" :class="{blue: material.isogroup == 'p'}">P</p>
                            <p @click="changeSelectedProperty('group', 'm')" class="pointer padding_small dark radius" :class="{blue: material.isogroup == 'm'}">M</p>
                            <p @click="changeSelectedProperty('group', 'k')" class="pointer padding_small dark radius" :class="{blue: material.isogroup == 'k'}">K</p>
                            <p @click="changeSelectedProperty('group', 'n')" class="pointer padding_small dark radius" :class="{blue: material.isogroup == 'n'}">N</p>
                            <p @click="changeSelectedProperty('group', 's')" class="pointer padding_small dark radius" :class="{blue: material.isogroup == 's'}">S</p>
                            <p @click="changeSelectedProperty('group', 'h')" class="pointer padding_small dark radius" :class="{blue: material.isogroup == 'h'}">H</p>
                            <p class="padding_small">{{groupName}}</p>
                        </div>
                        <p>Zerspanbarkeit:</p>
                        <div class="flex">
                            <input type="range" min="0" max="4" :value="material.machinability" @input="changeSelectedProperty('machinability', $event.target.value)">
                            <p>{{machinabilityText}}</p>
                        </div>

                    </div>
                </div> -->
                
                <div class="box" v-if="material_customized">
                    <p @click="material_save()" class="padding_small blue radius pointer fitContent">Anpassung speichern</p>
                    <p>{{save_message}}</p>
                </div>
                
                <!-- <select :value="material.type" @input="changeSelectedProperty('type', $event.target.value)" >
                    <option value="rod">{{dict('rod')}}</option>
                    <option value="piece">{{dict('piece')}}</option>
                </select> -->
            </div>
        </div>
    </div> 
</template>

<script>

export default {
    name: "Material",
    data() {
        return {
            save_message: ""
        }
    },
    created() {
        let materialName = this.$store.state.calculation.material.name
        console.log(materialName);
    },
    computed: {
        groupName() {
            let names = {"p": "Stahl", "m": "Edelstahl", "k": "Gusseisen", "n": "Nichteisen", "s": "Superlegierung", "h": "Hart"}
            return names[this.material.group]
        },
        machinabilityText() {
            let text = ["sehr schwer", "schwer", "mittel", "gut", "sehr gut"]
            return text[this.material.machinability]
        },
        
        materials() {
            return this.$store.getters.materials
        },
        materials_names() {
            let names = []
            const materials = this.$store.getters.materials
            names = materials.map(mat => mat.name)
            // for (const m in materials) {
            //     names.push(materials[m][0])
            // }
            return names
        },
        materials_codes() {
            const materials = this.$store.getters.materials
            return materials.map(mat => JSON.stringify(mat.codes).replace(/(\["|"\]|\\)/g, "").replace(/","/g, "; ").replace(/"/g, ""))
        },
        verifiedSteps() {
            return this.$store.getters.verifiedSteps
        },

        material() {
            return this.$store.getters.material
            // if(material.codes) material.codes = JSON.stringify(material.codes).replace(/(\["|"\]|\\)/g, "").replace(/","/g, "; ").replace(/"/g, "")
            // return material
        },
        material_selected() {
            return this.$store.getters.material_selected
        },

        types_available() {
            //  Check by process type
            const processType = this.$store.state.calculation.processes[0].name
            console.log(processType);
            let processes = []
            switch (processType) {
                case "turning":
                        processes = ["rod", "piece"]
                    break;
                case "milling":
                        processes = ["rod", "piece"]
                    break;
            }
            return processes
        },
        price() {
            return this.$store.getters.material_price
            // const type = this.material.type
            // if (type == "rod") {
            //     const diameter = this.$store.getters.subProcess("dimensions")
            //     console.log(diameter);
            // }
            // return 0
        },

        material_customized() {
            return this.$store.getters.material_isCustomized
            // const raw = this.materials[this.material_selected]
            // if(!raw) return 1

            // console.log(raw);
            // return 1
        }
    },
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        changeSelected(material) {
            console.log(this.$store.state);
            this.$store.commit("material_changeSelected", material)
            //  set materialinformation from Database
            const info = this.$store.getters.material_information(material)
            if (info) {
                this.$store.commit("material_setInformation", ["name", info.name])
                this.$store.commit("material_setInformation", ["description", info.description])
                this.$store.commit("material_setInformation", ["codes", info.codes])
                this.$store.commit("material_setInformation", ["id", info.id])
                this.$store.commit("material_setInformation", ["isogroup", info.isogroup])
                this.$store.commit("material_setInformation", ["machinability", info.machinability])
                const type = this.material.type
                const price = this.$store.getters.material_calculatedPrice(this.material, info, type)
                this.$store.commit("material_setInformation", ["price_"+type, price])
            }
            
            this.$store.dispatch("refresh")
        },
        customizeMaterial(i, value) {
            this.$store.commit("materialInformation_change", [this.catalogue_selected, i, value])
        },

        changeSelectedProperty(key, value) {
            if(key == "codes") value = value.replace(/"/g, "").split("; ")
            this.$store.commit("material_setInformation", [key, value])
            this.$store.dispatch("refresh")
        },
        
        material_save() {
            this.save_message = ""
            if (!this.material.name) {
                this.save_message = "Bitte geben Sie dem Material einen Namen"
                return 
            }
            this.$store.commit("material_saveNew", this.material)
        }
    },
};
</script>

<style scoped>
    #material_useData{margin-top: 4px;}
    #material_useData>img{
        height: 1.5em!important; 
        width: 1.5em!important;
    }
    input[type="number"]{
        width: 4em;
    }
    .selectGrid{
        display: grid;
        grid-template-columns: 128px 1fr;
        grid-column-gap: 1em;
        grid-row-gap: 4px;
    }
</style>
